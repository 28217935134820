// import React, { useState } from 'react';
// import '../styles/mainPage.css'
// import ConfirmModal from './ConfirmModal';
// import OrderConfirmationModal from './OrderConfirmationModal';
// import FormModal from './FormModal';
// import SuccessOrder from './SucessOrder'
// import { Routes, Route } from 'react-router-dom';
// import Navbar from './Navbar';

// const MainPage = ( {view} ) => {
//   console.log(view);

//   return (
// <>
// <div className='mainPage'>
// main page 
//       <div>
//       {view === '/confirm-order' && <OrderConfirmationModal />}
//       {view === '/update-address' && <FormModal />}
//       {view === '/success-order' && <SuccessOrder />}
//       {!view && <div>Home Page Content</div>}
//     </div>
//     </div>
// </>
// )
// }

// export default MainPage


















import React from 'react';
import '../styles/mainPage.css';
import OrderConfirmationModal from './OrderConfirmationModal';
import FormModal from './FormModal';
import SuccessOrder from './SucessOrder';

const MainPage = ({ view }) => {
  console.log(view);

  return (
    <div className='mainPage'>
      {/* <div>Main Page Content</div> */}
      <div>
        {view === 'confirm-order' && <OrderConfirmationModal />}
        {view === 'update-address' && <FormModal />}
        {view === 'success-order' && <SuccessOrder />}
        {!view && <div>Home Page Content</div>}
      </div>
    </div>
  );
}

export default MainPage;
