import axios from "axios";

const url  = process.env.REACT_APP_URL_PRODUCTION;



export const updateShippingAddress = async(data)=>{
    try{
        console.log("inside api  ",data)

        return await axios.post(`${url}/v1/shopify/update-shipping-address`,{data});
        console.log("data here : ",data)

    }catch(error){
        
        return {err:error}
    }
}



export const getOrderIdByToken = async(data)=>{
    try{
        console.log("inside api  ",data)

        return await axios.post(`${url}/v1/shopify/get-order-details`,{data})

    }catch(error){
        return {err:error}
    }
}


export const fetchOrderDetails = async(data)=>{
    try{
        console.log("inside api  ",data)

        return await axios.post(`${url}/v1/shopify/fetch-order-details`,{data})

    }catch(error){
        return {err:error}
    }
}

export const updateOrderTags = async(data)=>{
    try{

        return await axios.post(`${url}/v1/shopify/update-order-tag`,{data})

    }catch(error){
        return {err:error}
    }
}