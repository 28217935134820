// // src/Components/Footer.js
// import React from 'react';
// import { Link } from 'react-router-dom';
// // import './Footer.css';
// import '../styles/Footer.css'

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="footer-container">
//         <div className="footer-left">
//           <h3>BlobCity</h3>
//           <p>1234 Mango Lane</p>
//           <p>City, State, 56789</p>
//         </div>
//         <div className="footer-right">
//           <ul className="footer-links">
//             <li><Link to="/about">About Us</Link></li>
//             <li><Link to="/contact">Contact</Link></li>
//             <li><Link to="/privacy">Privacy Policy</Link></li>
//             <li><Link to="/terms">Terms of Service</Link></li>
//           </ul>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;











// src/Components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer-container">

        <div className="footer-left">
          {/* <span>BlobCity, 1234 Mango Lane, City, State, 56789</span> */}
          <span>WeWork, Enam Sambhav, C-20, G Block Rd, G Block BKC, Bandra Kurla Complex, Bandra East, <br/> Mumbai, Maharashtra 400051. </span>
        </div>
        

        <div className="footer-right">
          <Link to="https://myalphonso.com/policies/refund-policy" className="footer-link">Policy Refund</Link>
          <Link to="https://myalphonso.com/policies/privacy-policy" className="footer-link">Privacy Policy</Link>
          <Link to="https://myalphonso.com/policies/terms-of-service" className="footer-link">Terms Of Service</Link>
          <Link to="https://myalphonso.com/policies/contact-informatio" className="footer-link">Contact</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
