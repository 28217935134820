
import React, { useState, useEffect } from 'react';
import '../styles/OrderConfirmationModal.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; 
import { Bars } from 'react-loader-spinner';
import { updateOrderTags } from '../api/api';
import { updateShippingAddress, getOrderIdByToken, fetchOrderDetails } from '../api/api';
import logo from '../assets/MY_ALPHONSO_LOGO.png'

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';

const OrderConfirmationModal = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);


  console.log(orderId);



  const getTagStatus = async (data) => {

    console.log("inside handleSubmit");
  
  

    console.log("data:", data);

    try {
      console.log('inside try');
      const response = await updateOrderTags(data);
      console.log("Response from API:", response);
      // setLoading(true);
      if(response.status==200){
      setLoading(false);    
    }
      

    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("Unable to update address");
    }
  };



  const getData = async (token) => {

    try {
      console.log('Inside try of getData');
      const response = await getOrderIdByToken(token);
      console.log("Response from API getOrderIdByToken:", response);

      const orderId = response?.data?.data[0]?.orderId;
      console.log("Response from API orderId:", orderId);
      setOrderId(orderId);

      getTagStatus(orderId);
      

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toast.error("Error in fetching customer data");
    }
  };
  const getTokenFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    console.log('Token:', token);

    getData(token);

  };


useEffect(() => {
    getTokenFromUrl();
  }, []);



  return (

    <>
    
      {/* <Navbar /> */}
   
    <div className="modal">

      <div className={`modal-content ${loading ? 'blurred' : ''}`}>

      <div className='logoContainer'>
              {/* <img src='../' alt="" /> */}
              {/* <img className='logo' src={logo} alt="logo" /> */}
            </div>

        <div className="confirmation-icon-container">
          <FontAwesomeIcon icon={faCheckCircle} className="confirmation-icon" />
        </div>



        {/* <br /> */}
        {/* <br /> */}
        <h2 id='headColor'>Order Verified</h2>
        <p id='para'>Your shipping address has now been verified. Your order will be shipped out soon.</p>
        {/* <br /> */}
        <p> You will receive tracking details once shipped.</p>
        {/* <p>We will contact you shortly.</p> */}
      </div>
      {loading && (
        <div className="loader-container">
          <Bars type="TailSpin" color="#FFB300" height={50} width={50} />
        </div>
      )}
    </div>

    </>
  );
};

export default OrderConfirmationModal;
