
import React, { useState, useEffect } from 'react';
import '../styles/OrderConfirmationModal.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; 
import { Bars } from 'react-loader-spinner';
import { updateOrderTags } from '../api/api';
import { updateShippingAddress, getOrderIdByToken, fetchOrderDetails } from '../api/api';
import logo from '../assets/MY_ALPHONSO_LOGO.png'

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const OrderConfirmation = ({ onClose }) => {


//   console.log(orderId);






  return (
    <div className="modal">

      <div className="modal-content "> 

      <div className='logoContainer'>
              <img className='logo' src={logo} alt="logo" />
            </div>

        <div className="confirmation-icon-container">
          <FontAwesomeIcon icon={faCheckCircle} className="confirmation-icon" />
        </div>


        <h2>Order Verified</h2>
        <p>Your shipping address has now been verified. Your order will be shipped out soon.</p>
        {/* <br /> */}
        <p> You will receive tracking details once shipped.</p>
        {/* <p>We will contact you shortly.</p> */}
      </div>
      
      
    </div>
  );
};

export default OrderConfirmation;
