
import React, { useState, useEffect } from 'react';
import '../styles/FormModal.css';
import { updateShippingAddress, getOrderIdByToken, fetchOrderDetails } from '../api/api';
import toast from 'react-hot-toast';
import { Bars } from 'react-loader-spinner';
import { useParams ,Navigate, useNavigate} from 'react-router-dom';
import logo from '../assets/MY_ALPHONSO_LOGO.png'

const FormModal = () => {
  const [formData, setFormData] = useState({
    country: '',
    address: '',
    apartment: '',
    city: '',
    state: '',
    pincode: '',
  });
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);


  const navigate = useNavigate();


  
  const getData = async (token) => {

    try {
      console.log('Inside try of getData');
      const response = await getOrderIdByToken(token);
      console.log("Response from API getOrderIdByToken:", response);

      const orderId = response?.data?.data[0]?.orderId;
      console.log("Response from API orderId:", orderId);
      setOrderId(orderId);

      const orderDetails = await fetchOrderDetails(orderId);
      console.log('Order details:', orderDetails);
      const shippingAddress = orderDetails?.data?.data?.order?.shipping_address;

      setFormData({
        address: shippingAddress.address1,
        apartment: shippingAddress.address2,
        city: shippingAddress.city,
        state: shippingAddress.province,
        country: shippingAddress.country,
        pincode: shippingAddress.zip,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toast.error("Error in fetching customer data");
    }
  };


  const getTokenFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    console.log('Token:', token);

    getData(token);

  };


  useEffect(() => {
    getTokenFromUrl();



  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {

    console.log("inside handleSubmit");
    event.preventDefault();
    const data = {
      orderId,
      newAddress: {
        address1: formData.address,
        address2: formData.apartment,
        city: formData.city,
        province: formData.state,
        country: formData.country,
        zip: formData.pincode,
      },
    };

    console.log("data:", data);
    try {
      console.log('inside try');
      setLoading(true);

      const response = await updateShippingAddress(data);
      console.log("Response from API:", response);
      
      if(response.status==200){
        console.log("inside if ");
      toast.success("Address Updated Successfully");
      navigate('/success-order'); 
      setLoading(false);
    }else{
      console.log("Response from API in error:", response);
      const ResponseError = response?.err?.response?.data?.details.shipping_address[0];
      console.log('ResponseError : ',ResponseError);
      toast.error(ResponseError);
      setLoading(false);
    }
      

    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("Unable to update address");
    }
  };

  return (
    <>
      <div className={`formPage ${loading ? 'blur' : ''}`}>
        <div className="formPage-content">
          <form onSubmit={handleSubmit}>
            {/* <div className='logoContainer'>
              <img className='logo' src={logo} alt="logo" />
            </div> */}
            <h2>Review your address</h2>
            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Apartment:</label>
              <input
                type="text"
                name="apartment"
                value={formData.apartment}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>City:</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>PIN code:</label>
              <input
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>State:</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Country:</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Save Address</button>
          </form>
        </div>
        {loading && (
          <div className="loader-container">
            <Bars type="TailSpin" color="#FFB300" height={50} width={50} />
          </div>
        )}
      </div>
    </>
  );
};

export default FormModal;
