// import './App.css';
// import MainPage from './Components/MainPage';
// import { Routes, Route } from 'react-router-dom';
// import FormModal from './Components/FormModal';
// import OrderConfirmationModal from './Components/OrderConfirmationModal';
// import OrderConfirmation from './Components/SucessOrder';
// import Navbar from './Components/Navbar';
// import Footer from './Components/Footer';

// function App() {
//   return (
//     <div className="App">
      

//      <Navbar />

//       <Routes>

//         <Route path="/" element={<MainPage />} />
//         <Route path="/confirm-order" element={<MainPage view="confirm-order"/>} />
//         <Route path="/Update-address" element={<MainPage  view="Update-address"/>} />
//         <Route path="/success-order" element={<MainPage  view="success-order"/>} />
//       </Routes>

//       <Footer/>

   
      
//     </div>
//   );
// }

// export default App;

















import './App.css';
import MainPage from './Components/MainPage';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/confirm-order" element={<MainPage view="confirm-order" />} />
        <Route path="/update-address" element={<MainPage view="update-address" />} />
        <Route path="/success-order" element={<MainPage view="success-order" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
