import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; 

import '@fortawesome/fontawesome-free/css/all.min.css'; 
import mangoLogo from '../assets/MY_ALPHONSO_LOGO.png'
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar" style={{  borderRadius: '5px solid red', zIndex: 991 }}>
            <div className="navbar-container">

            <div className="menu-icon" onClick={toggleMenu}>
                    {/* <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i> */}
                    {/* <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i> */}
                    <span>{
                        isOpen ?

                        (
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-close" fill="none" viewBox="0 0 18 17" width='20' >
                            <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor">
                          </path></svg>
                        )
   :  (           <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-hamburger" fill="none" viewBox="0 0 18 16" width='20' style={{fontSize:'10px'}}>
  <path d="M1 .5a.5.5 0 100 1h15.71a.5.5 0 000-1H1zM.5 8a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1A.5.5 0 01.5 8zm0 7a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1a.5.5 0 01-.5-.5z" fill="currentColor" >
</path></svg>
)

              
            }</span>
                </div>

                <Link to="https://myalphonso.com/" className="navbar-logo">
                    <img src={mangoLogo} alt="Mango Logo" />
                </Link>
                
                <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="https://myalphonso.com" className="nav-links" onClick={() => setIsOpen(false)}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="https://myalphonso.com/collections/all" className="nav-links" onClick={() => setIsOpen(false)}>
                            Catalog
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="https://myalphonso.com/pages/contact" className="nav-links" onClick={() => setIsOpen(false)}>
                        contact
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="https://myalphonso.com/pages/faqs" className="nav-links" onClick={() => setIsOpen(false)}>
                            FAQ's
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
